import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import { useNavigate } from "react-router-dom";

const Banner = ({ bannervideo, heading, subheading, btn1 }) => {
 const navigate=useNavigate();
  const redirecttopage=()=>{
    navigate("/createavatar",{state:"female"})
  }
  return (
    <div className="featureclass bannerclass">
    
       <video className="rounded-xl w-full" autoPlay loop muted src={bannervideo}></video>
    
    </div>
  );
};

export default Banner;
