import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Features from "./components/Features";
import About from "./components/About";
import Explore from "./components/Explore";
import Pricing from "./components/Pricing";
import Login from "./components/Login";
import AvatarGenerate from "./components/Avatargenerate";
import Result from "./components/Result";
import Headline from "./components/Headline"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollRestoration from "./components/ScrollRestoration";
import React, { useEffect } from 'react';
import axios from 'axios';
import ForgotPassword from "./components/Forgotpassword";
import Register from "./components/Register";
import Successpage from "./components/Successpage"

function App() {
  const API_URL = 'https://api.dogiwa-uat.bzbug.com';

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/data`);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollRestoration />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Headline />
                <Home />
                <Features />
                <Explore />
                <About />
                <Pricing />
              </>
            }
          ></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Register />}></Route>
          <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
          <Route path="/success" element={<Successpage />}></Route>
          <Route
            path="/createavatar"
            element={
              <>
                <Navbar />
                <AvatarGenerate />
              </>
            }
          ></Route>
          <Route
            path="/result"
            element={
              <>
                <Navbar />
                <Result />
              </>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
