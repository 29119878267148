import React, { useEffect, useState } from "react";
import { CiMenuBurger } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import logohead from "../images/logoheader.jpg";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate=useNavigate();

  //state for detecting menu is open or close for small screens only
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  // state for detecting scroll
  const [scrollBg,setScrollBg]=useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrollBg(true);
      } else {
        setScrollBg(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

  //function to toggle isMenuOpen class
  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  const navItems = [
    {
      id: "1",
      name: "Overview",
      path: "overview",
    },
    { id: "2", name: "Feature", path: "feature" },
    { id: "3", name: "About", path: "about" },
    { id: "4", name: "Explore", path: "explore"  },
    { id: "5", name: "Pricing", path: "pricing" },
  ];

  return (
    <>
      <nav className= {`md:px-14 p-4 max-w-screen-2xl text-primary fixed top-0 right-0 left-0 ${scrollBg?'bg-white':'bg-transparent'}`}>
        <div className="text-lg container mx-auto flex justify-between items-center font-medium">
          <div className="flex items-center space-x-14">
            <a
              href="/"
              className="text-2xl font-semibold flex items-center space-x-3 text-primary"
            >
              <img
                src={logohead}
                alt=""
                className="w-10 inline-block items-center"
              />
              <span className="text-4xl">Dojiwa</span>
            </a>
            {/* showing navigation using map */}
            <ul className="md:flex space-x-12 hidden text-primary">
              {navItems.map((item) => (
                <Link
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                  className="hover:text-gray-300 block cursor-pointer"
                  key={item.id}
                  to={item.path}
                  
                >
                  {item.name}
                </Link>
              ))}
            </ul>
          </div>

          <div className="sapce-x-12 hidden md:flex items-center">
            {/* signup button */}
            <button className="bg-secondary py-2 px-4 rounded-lg hover:bg-indigo-600 hover:text-white" onClick={()=>navigate("/login")}>
              Sign up/Sign In
            </button>
          </div>

          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none focus:text-gray-300 "
            >
              {isMenuOpen ? (
                <IoMdClose className="w-7 h-6 text-primary" />
              ) : (
                <CiMenuBurger className="w-7 h-6 text-primary" />
              )}
            </button>
          </div>
        </div>
      </nav>
      {/* navitem for mobile devices */}
      <div
        className={`md:hidden space-y-4 px-4 pt-24 pb-25 bg-secondary text-xl ${
          isMenuOpen ? "block fixed top-0 right-0 left-0" : "hidden"
        } `}
      >
        {navItems.map((item) => (
          <Link
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-80}
          className="hover:text-gray-300 block text-white cursor-pointer"
          key={item.id}
          to={item.path}
          onClick={toggleMenu}
          >
            {item.name}
          </Link>
        ))}
      </div>
    </>
  );
};

export default Navbar;
