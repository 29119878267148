import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import addimg from "../images/addimg.png";
import select1 from "../images/image1.png"
import select2 from "../images/image2.png"
import select3 from "../images/image3.png"
import select4 from "../images/image4.png"
import select5 from "../images/image5.png"
import select6 from "../images/image6.png"
import select7 from "../images/image7.png"
import select8 from "../images/image8.png"
import select9 from "../images/image9.png"
import select10 from "../images/image10.png"
import select11 from "../images/image11.png"
import select12 from "../images/image12.png"
import select13 from "../images/image13.png"
import select14 from "../images/image14.png"
import select15 from "../images/image15.png"
import select16 from "../images/image16.png"
import select17 from "../images/image17.png"
import select18 from "../images/image18.png"
import select19 from "../images/image19.png"
import select20 from "../images/image20.png"
import select21 from "../images/image21.png"
import select22 from "../images/image22.png"
import select23 from "../images/image23.png"
import select24 from "../images/image24.png"
import select25 from "../images/image25.png"
import select26 from "../images/image26.png"
import select27 from "../images/image27.jpg"
import select28 from "../images/image28.png"

import male1 from "../images/male1.png"
import male2 from "../images/male2.png"
import male3 from "../images/male3.png"
import male4 from "../images/male4.png"
import male5 from "../images/male6.png"
import male6 from "../images/male7.png"
import male7 from "../images/male8.png"
import male8 from "../images/male9.jpg"
import male9 from "../images/male10.png"
import male10 from "../images/male11.png"
import male11 from "../images/male12.png"
import male12 from "../images/male13.png"
import male13 from "../images/male15.png"
import male14 from "../images/male16.png"
import male15 from "../images/male17.png"
import male16 from "../images/male18.png"
import male17 from "../images/male19.png"
import male18 from "../images/male20.png"
import male19 from "../images/male21.png"
import male20 from "../images/male22.png"
import male21 from "../images/male23.png"
import male22 from "../images/male24.png"
import male23 from "../images/male25.png"
import male24 from "../images/male26.png"
import male25 from "../images/male27.png"
import male26 from "../images/male28.png"

const Avatargenerate = () => {
  const Location=useLocation();

  //object for image selection
  const imgObj = [
    {
      id: 1,
      imgobfe: select1,
      imgobmale: male1,
      name: "Name",
      prompt:""
    },
    {
      id: 2,
      imgobfe: select2,
      imgobmale: male2,
      name: "Name",
    },
    {
      id: 3,
      imgobfe: select3,
      imgobmale: male3,
      name: "Name",
    },
    {
      id: 4,
      imgobfe: select4,
      imgobmale: male4,
      name: "Name",
    },
    {
      id: 5,
      imgobfe: select5,
      imgobmale: male5,
      name: "Name",
    },
    {
      id: 6,
      imgobfe: select6,
      imgobmale: male6,
       name: "Name",
    },
    {
      id: 7,
      imgobfe: select7,
      imgobmale: male7,
      name: "Name",
    },
    {
      id: 8,
      imgobfe: select8,
      imgobmale: male8,
      name: "Name",
    },
    {
      id: 9,
      imgobfe: select9,
      imgobmale: male9,
      name: "Name",
    },
    {
      id: 10,
      imgobfe: select10,
      imgobmale: male10,
      name: "Name",
    },
    {
      id: 11,
      imgobfe: select11,
      imgobmale: male11,
      name: "Name",
    },
    {
      id: 12,
      imgobfe: select12,
      imgobmale: male12,
      name: "Name",
    },
    {
      id: 13,
      imgobfe: select13,
      imgobmale: male13,
      name: "Name",
    },
    {
      id: 14,
      imgobfe: select14,
      imgobmale: male14,
      name: "Name",
    },
    {
      id: 15,
      imgobfe: select15,
      imgobmale: male15,
      name: "Name",
    },
    {
      id: 16,
      imgobfe: select16,
      imgobmale: male16,
      name: "Name",
    },
    {
      id: 17,
      imgobfe: select17,
      imgobmale: male17,
      name: "Name",
    },
    {
      id: 18,
      imgobfe: select18,
      imgobmale: male18,
      name: "Name",
    },
    {
      id: 19,
      imgobfe: select19,
      imgobmale: male19,
      name: "Name",
    },
    {
      id: 20,
      imgobfe: select20,
      imgobmale: male20,
      name: "Name",
    },
    {
      id: 21,
      imgobfe: select21,
      imgobmale: male21,
      name: "Name",
    },
    {
      id: 22,
      imgobfe: select22,
      imgobmale: male22,
      name: "Name",
    },
    {
      id: 23,
      imgobfe: select23,
      imgobmale: male23,
      name: "Name",
    },
    {
      id: 24,
      imgobfe: select24,
      imgobmale: male24,
      name: "Name",
    },
    {
      id: 25,
      imgobfe: select25,
      imgobmale: male25,
      name: "Name",
    },
    {
      id: 26,
      imgobfe: select26,
      imgobmale: male26,
      name: "Name",
    },
    {
      id: 27,
      imgobfe: select27,
      imgobmale: male8,
      name: "Name",
    },
    {
      id: 28,
      imgobfe: select28,
      imgobmale: male8,
      name: "Name",
    },
  
  ];
  const [formvalues, setFormValues] = useState({
    gender: "",
    image: {
      placeholder: addimg,
      file: null,
    },
    avatarSelect:""
  });

  // Ref for file input
  const fileInputref = useRef(null);

  //states for form error
  const [genderError, setgenderError] = useState("");
  const [imgerror, setImgerr] = useState("");
  const [avatarError,setAvatarError]=useState("");

  //state for options
  const [category, setCategory] = useState(1);

  //state to handle avatar selection
  const [selectAvatar,setSelectAvatar]=useState();

  //state store image to be send to form
  const [selectImageAvatar,setSelectAvatarImage]=useState(null);

  //function for the gender div to select the value when its div is clicked
  const   handleGenderSelect = (genderval) => {
    setFormValues({ ...formvalues, gender: genderval });
  };

  //onClick handler for avatar selection
  const handleSelectAvatar=(id,imagesrc)=>{
    setSelectAvatar(id);
    setFormValues({...formvalues,avatarSelect:imagesrc})
  }

  //onchange handler when the value of the field changes
  const onChangeHandler = (event) => {
    setFormValues({ ...formvalues, [event.target.name]: event.target.value });
  };

  //function for choosing image when user click on image(plus sign)
  const handleImageClick = () => {
    fileInputref.current.click();
  };

  //onchangehandler for image
  const handleImageChange = (event) => {
    const reader = new FileReader();
    reader.onload = (r) => {
      setFormValues({
        ...formvalues,
        image: {
          placeholder: r.target.result,
          file: event.target.files[0],
        },
      });
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formvalues);
    if (!formvalues.gender) {
      setgenderError("Select a value");
    }
    if(!formvalues.image.file){
      setImgerr("Select a photo");
    }
    if(!formvalues.avatarSelect){
      setAvatarError("Select an Avatar");
    }
    console.log(formvalues);
  };

  return (
    <div className="my-24 px-10 w-100 max-w-screen-2xl mx-auto" id="feature">
      <div className="w-100">
        <div className="">
          <span className="text-primary font-medium text-base bg-gradient-to-b from-teal-300 to-blue-500 rounded-xl py-2 px-3">
            Yours Credits:5
          </span>
          <form
            action=""
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="mt-5 mr-10">
              <h1 className="text-2xl  font-medium">Select Gender</h1>
              <div className="md:flex grid gap-5 justify-center ">
                <div
                  className={` md:py-8 md:px-20 py-6 px-16 rounded-xl  cursor-pointer ${formvalues.gender==="male"? "border-2 border-green-600":"border-2 border-gray"}`}
                  onClick={() => {
                    handleGenderSelect("male");
                  }}
                >
                  <input
                    className="font-extrabold custom-radio"
                    type="radio"
                    id="male"
                    name="gender"
                    value="male"
                    checked={formvalues.gender === "male"}
                    onChange={onChangeHandler}
                  />
                  <label
                    for="html"
                    className="ml-6 text-xl font-medium cursor-pointer"
                  >
                    Male
                  </label>
                </div>
                <div
                    className={` md:py-8 md:px-20 py-6 px-16 rounded-xl  cursor-pointer ${formvalues.gender==="female" ? "border-2 border-green-600":"border-2 border-gray"}`}
                  onClick={() => {
                    handleGenderSelect("female");
                  }}
                >
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="female"
                    checked={formvalues.gender === "female"}
                  />
                  <label
                    for="html"
                    className="ml-6 text-xl font-medium  cursor-pointer"
                  >
                    Female
                  </label>
                </div>
              </div>
              <p className="text-red-500">{genderError}</p>
            </div>

            <div className="mt-5 mr-10 ">
              <label htmlFor="" className="text-2xl  font-medium">
                Upload Image
              </label>
              <div className="border-2 border-gray rounded-xl">
                {/* img tag for preview */}
                <div className="flex flex-col justify-center items-center">
                  <img
                    className="h-100 w-100 rounded-full cursor-pointer"
                    onClick={handleImageClick}
                    style={{ height: "86px", width: "86px" }}
                    src={formvalues.image.placeholder}
                    alt=""
                  />
                  <p className="text-xl font-medium">Choose an image</p>
                </div>
                <input
                  className="opacity-0"
                  ref={fileInputref}
                  type="file"
                  name="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleImageChange}
                />
              </div>
              <p className="text-red-500">{imgerror}</p>
            </div>

            <div className="mt-5 mr-10 ">
              <label htmlFor="" className="text-2xl  font-medium">
                Select the style you want
              </label>
              <div className="mt-2 gap-x-3 md:flex grid grid-cols-2 gap-y-2 cursor-pointer">
                <span
                  className={`text-primary font-medium md:text-lg text-base bg-gray-300 rounded-xl py-2 px-3 hover:text-white ${
                    category === 1
                      ? "bg-gradient-to-b from-teal-300 to-blue-500"
                      : null
                  } `}
                  onClick={() => setCategory(1)}
                >
                  Option 1
                </span>
                <span
                  className={`text-primary font-medium md:text-lg bg-gray-300 text-base  hover:text-white rounded-xl py-2 px-4 ${
                    category === 2
                      ? "bg-gradient-to-b from-teal-300 to-blue-500"
                      : null
                  }`}
                  onClick={() => setCategory(2)}
                >
                  Option 2
                </span>
                <span
                  className={`text-primary font-medium md:text-lg bg-gray-300 text-base  to-blue-500 hover:text-white rounded-xl py-2 px-4 ${
                    category === 3
                      ? "bg-gradient-to-b from-teal-300 to-blue-500"
                      : null
                  }`}
                  onClick={() => setCategory(3)}
                >
                  Option 3
                </span>
                <span
                  className={`text-primary font-medium md:text-lg bg-gray-300 text-base  to-blue-500  hover:text-white rounded-xl py-2 px-4 ${
                    category === 4
                      ? "bg-gradient-to-b from-teal-300 to-blue-500"
                      : null
                  } `}
                  onClick={() => setCategory(4)}
                >
                  Option 4
                </span>
                <span
                  className={`text-primary font-medium md:text-lg bg-gray-300 text-base  to-blue-500  hover:text-white rounded-xl py-2 px-4 flex justify-center items-center ${
                    category === 5
                      ? "bg-gradient-to-b from-teal-300 to-blue-500"
                      : null
                  } `}
                  onClick={() => setCategory(5)}
                >
                <FaPencilAlt className=""/>  Custom Prompt
                </span>
              </div>
            </div>
            {/* <div className="mt-5 mr-10 ">
              <div className="flex justify-center items-center border-2 border-gray m-auto rounded-full px-20 bg-secondary font-semibold text-lg text-white gap-3 py-4" style={{width:"20%"}}>
                <div className="cursor-pointer  bg-gradient-to-b from-teal-300 to-blue-500 w-full rounded-lg">Male</div>
                <div className="cursor-pointer">Female</div>
              </div>
            </div>
             */}
             
            <div className="mt-5 mr-10">
            <p className="text-red-500">{avatarError}</p>
              <div className=" gap-2 xl:grid-cols-4 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 ">
                {imgObj.map((imgEle, index) => (
                  <div key={index}>
                    {/* <TiTick /> */}
                    {formvalues.gender === "female" || formvalues.gender==="" ? (
                      <img
                        className={`imgstyle rounded-lg cursor-pointer ${imgEle.id===selectAvatar?"border-4 border-green-600":null} `}
                        src={imgEle.imgobfe}
                        alt=""
                        onClick={()=>handleSelectAvatar(imgEle.id, imgEle.imgobfe)}
                      />
                    ) : (
                      <img
                      className={`imgstyle rounded-lg cursor-pointer ${imgEle.id===selectAvatar?"border-4 border-green-600":null} `}
                        src={imgEle.imgobmale}
                        alt=""
                        onClick={()=>handleSelectAvatar(imgEle.id, imgEle.imgobfe)}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <button
              type="submit"
              className="py-2 px-5 bg-secondary font-semibold text-white rounded-xl hover:bg-primary transition-all duration-300 mt-4"
            >
              Generate
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Avatargenerate;
