import React from "react";
import { FaCheckCircle } from "react-icons/fa";
const Pricing = () => {
  const pricingCards = [
    {
      id: 1,
      name: "Starter",
      description: "For small scale business",
      price: "₹10",
      featuresincluded: [
        "lorem ipusum",
        "lorem ipusum",
        "lorem ipusum",
        "lorem ipusum",
      ],
      featuresNotincluded: ["lorem ipusum", "lorem ipusum"],
    },
    {
      id: 2,
      name: "Lite",
      description: "For medium scale business",
      price: "₹20",
      featuresincluded: [
        "lorem ipusum",
        "lorem ipusum",
        "lorem ipusum",
        "lorem ipusum",
      ],
      featuresNotincluded: ["lorem ipusum", "lorem ipusum"],
    },
    {
      id: 3,
      name: "Pro",
      description: "For large scale business",
      price: "₹30",
      featuresincluded: [
        "lorem ipusum",
        "lorem ipusum",
        "lorem ipusum",
        "lorem ipusum",
      ],
      featuresNotincluded: ["lorem ipusum", "lorem ipusum"],
    },
  ];
  return (
    <div className="md:px-14 p-4 max-w-s mx-auto" id="pricing">
      <div className="text-center">
        <h1 className="md:text-5xl text-3xl font-extrabold text-primary mb-3">
          Plans<span className="text-base text-tartiary">/monthly</span>
        </h1>
      </div>
      {/* pricing cards */}
      <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-10 mt-14 md:w-11/12 mx-auto">
        {pricingCards.map((info, index) => (
          <div class="border py-10 md:px-6 px-4 rounded-lg shadow-2xl hover:-translate-y-4 transition-all duration-300">
            <h5 class="mb-4 text-xl text-xl text-gray-500 dark:text-gray-400 text-center">
              Standard plan
            </h5>
            <div class="flex items-baseline text-gray-900 dark:text-white text-center">
              <span class="text-3xl font-semibold">₹</span>
              <span class="text-5xl font-extrabold tracking-tight">49</span>
              <span class="ms-1 text-xl font-normal text-gray-500 dark:text-gray-400">
                /month
              </span>
            </div>
            <ul  class="space-y-5 my-7">
              <li class="flex items-center">
                <svg
                  class="flex-shrink-0 w-4 h-4 text-secondary dark:text-blue-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
                  2 team members
                </span>
              </li>
              <li class="flex">
                <svg
                  class="flex-shrink-0 w-4 h-4 text-secondary dark:text-blue-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
                  20GB Cloud storage
                </span>
              </li>
              <li class="flex">
                <svg
                  class="flex-shrink-0 w-4 h-4 text-secondary dark:text-blue-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">
                  Integration help
                </span>
              </li>
              <li class="flex line-through decoration-gray-500">
                <svg
                  class="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span class="text-base font-normal leading-tight text-gray-500 ms-3">
                  Sketch Files
                </span>
              </li>
              <li class="flex line-through decoration-gray-500">
                <svg
                  class="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span class="text-base font-normal leading-tight text-gray-500 ms-3">
                  API Access
                </span>
              </li>
              <li class="flex line-through decoration-gray-500">
                <svg
                  class="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span class="text-base font-normal leading-tight text-gray-500 ms-3">
                  Complete documentation
                </span>
              </li>
              <li class="flex line-through decoration-gray-500">
                <svg
                  class="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span class="text-base font-normal leading-tight text-gray-500 ms-3">
                  24×7 phone & email support
                </span>
              </li>
            </ul>
            <button
              type="button"
              class="text-white bg-secondary hover:bg-indigo-600 focus:ring-4 focus:outline-none focus:ring-blue-200 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-900 text-lg rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
            >
              Choose plan
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
 
export default Pricing;