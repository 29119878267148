import React, { useState } from "react";
import { FaGoogle } from "react-icons/fa";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const Login = () => {
  //base url of api
  const baseurl = "https://api-dogiwa-uat.bzbug.com";

  //login schema
  const loginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Enter email"),
    password: Yup.string().required("Enter password"),
  });

  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: loginSchema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      loginUser(values);
    },
  });

  const navigate = useNavigate();

  const togglelogin = () => {
    navigate("/signup");
  };

  const redirectToForgotpsw=()=>{
    navigate("/forgotpassword")
  }

  //api for logging of users
  const loginUser=async(e)=>{
    try{
      await axios.post(`${baseurl}/login`,{
        email:e.email,
        password:e.password
      })
      .then((response)=>{
        if(response.status===200){
          console.log("logged in successfully");
          
        }
        else{
          console.log(response.data.message);
          
        }
      })
    }
    catch(e){

    }
  }

  return (
    <div className="flex w-full h-screen loginscreen">
      <div className="w-full flex items-center justify-center">
        <div className="bg-white px-16  md:px-10 py-16 rounded-3xl border-2 border-gray">
          <h1>Dogiwa</h1>
          <h1 className="md:text-5xl text-3xl font-semibold">
            Hello Welcome Back
          </h1>
          <p className="font-medium text-lg text-gray-500 mt-4">
            Please Enter Your Details
          </p>
          <form action="" onSubmit={handleSubmit}>
            <div className="mt-5">
              <div className="mb-2">
                <label className="text-lg font-medium" htmlFor="">
                  Email
                </label>
                <input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="w-full border-2 border-gray-100 rounded-xl p-1 mt-1 bg-transparent"
                  placeholder="Enter your email"
                />
                {touched.email && errors.email ? (
                  <p className="text-red-500"> {errors.email} </p>
                ) : null}
              </div>
              <div>
                <label className="text-lg font-medium" htmlFor="">
                  Password
                </label>
                <input
                  name="password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="w-full border-2 bordre-gray-100 rounded-xl p-1 mt-1 bg-transparent"
                  placeholder="Enter your Password"
                />
                {touched.password && errors.password ? (
                  <p className="text-red-500"> {errors.password} </p>
                ) : null}
              </div>
            </div>
            <div className="mt-5 flex flex-col gap-y-4">
            <button type="submit" className="py-1 px-4 bg-secondary font-semibold text-white rounded-xl hover:bg-primary transition-all duration-300 text-lg ">
              Sign In
            </button>
            </div>
          </form>
          <div className="mt-5">
            <button className="text-violet-500 text-base font-medium" onClick={redirectToForgotpsw}>
              Forgot Password?
            </button>
          </div>
          <div className="mt-5 flex flex-col gap-y-4">
            <button className="flex border-2 rounded-lg justify-center items-center gap-2 py-1 px-4  font-semibold  rounded-xl  transition-all duration-300 text-lg ">
              <FaGoogle />
              Sign In with Google
            </button>
          </div>
          <div className="mt-8 flex justify-center gap-2 items-center">
            <p className="font-medium text-base"> Don't have an account?</p>
            <button
              className="text-violet-500 text-base font-medium"
              onClick={togglelogin}
            >
              Signup
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
