import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import select1 from "../images/image1.png"
import select2 from "../images/image2.png"
import select3 from "../images/image3.png"
import select4 from "../images/image4.png"
import select5 from "../images/image5.png"
import select6 from "../images/image6.png"
import select7 from "../images/image7.png"
import select8 from "../images/image8.png"
import select9 from "../images/image9.png"
import select10 from "../images/image10.png"
import select11 from "../images/image11.png"
import select12 from "../images/image12.png"
import select13 from "../images/image13.png"
import select14 from "../images/image14.png"
import select15 from "../images/image15.png"
import select16 from "../images/image16.png"
import select17 from "../images/image17.png"
import select18 from "../images/image18.png"
import select19 from "../images/image19.png"
import select20 from "../images/image20.png"
import select21 from "../images/image21.png"
import select22 from "../images/image22.png"
import select23 from "../images/image23.png"
import select24 from "../images/image24.png"
import select25 from "../images/image25.png"
import select26 from "../images/image26.png"
import select27 from "../images/image27.jpg"
import select28 from "../images/image28.png"
import male1 from "../images/male1.png"
import male2 from "../images/male2.png"
import male3 from "../images/male3.png"
import male4 from "../images/male4.png"
import male5 from "../images/male6.png"
import male6 from "../images/male7.png"
import male7 from "../images/male8.png"
import male8 from "../images/male9.jpg"
import male9 from "../images/male10.png"
import male10 from "../images/male11.png"
import male11 from "../images/male12.png"
import male12 from "../images/male13.png"
import male13 from "../images/male15.png"
import male14 from "../images/male16.png"
import male15 from "../images/male17.png"
import male16 from "../images/male18.png"
import male17 from "../images/male19.png"
import male18 from "../images/male20.png"
import male19 from "../images/male21.png"
import male20 from "../images/male22.png"
import male21 from "../images/male23.png"
import male22 from "../images/male24.png"
import male23 from "../images/male25.png"
import male24 from "../images/male26.png"
import male25 from "../images/male27.png"
import male26 from "../images/male28.png"


const Explore = () => {
  const navigate = useNavigate();

  const avatarsfemale = [
    {
      id: 1,
      imgsrc: select1,
      description: "desc1",
    },
    {
      id: 2,
      imgsrc: select2,
      description: "desc2",
    },
    {
      id: 3,
      imgsrc: select3,
      description: "desc3",
    },
    {
      id: 6,
      imgsrc: select4,
      description: "desc4",
    },
    {
      id: 5,
      imgsrc: select5,
      description: "desc5",
    },
    {
      id: 6,
      imgsrc: select6,
      description: "desc6",
    },
    {
      id: 7,
      imgsrc: select7,
      description: "desc7",
    },
    {
      id: 8,
      imgsrc: select8,
      description: "desc8",
    },
    {
      id: 9,
      imgsrc: select9,
      description: "desc9",
    },
    {
      id: 10,
      imgsrc: select10,
      description: "desc10",
    },
    {
      id: 11,
      imgsrc: select11,
      description: "desc11",
    },
    {
      id: 12,
      imgsrc: select12,
      description: "desc12",
    },
    {
      id: 13,
      imgsrc: select13,
      description: "desc13",
    },
    {
      id: 14,
      imgsrc: select14,
      description: "desc14",
    },
    {
      id: 15,
      imgsrc: select15,
      description: "desc15",
    },
    {
      id: 16,
      imgsrc: select16,
      description: "desc16",
    },
    {
      id: 17,
      imgsrc: select17,
      description: "desc17",
    },
    {
      id: 18,
      imgsrc: select18,
      description: "desc18",
    },
    {
      id: 19,
      imgsrc: select19,
      description: "desc19",
    },
    {
      id: 20,
      imgsrc: select20,
      description: "desc20",
    },
    {
      id: 21,
      imgsrc: select21,
      description: "desc21",
    },
    {
      id: 22,
      imgsrc: select22,
      description: "desc22",
    },
    {
      id: 23,
      imgsrc: select23,
      description: "desc23",
    },
    {
      id: 24,
      imgsrc: select24,
      description: "desc24",
    },
    {
      id: 25,
      imgsrc: select25,
      description: "desc25",
    },
    {
      id: 26,
      imgsrc: select26,
      description: "desc26",
    },
    {
      id: 27,
      imgsrc: select27,
      description: "desc27",
    },
    {
      id: 28,
      imgsrc: select28,
      description: "desc28",
    },
  ];
  const avatarsmale = [
    {
      id: 1,
      imgsrc: male1,
      description: "desc1",
    },
    {
      id: 2,
      imgsrc: male2,
      description: "desc2",
    },
    {
      id: 3,
      imgsrc: male3,
      description: "desc3",
    },
    {
      id: 4,
      imgsrc: male4,
      description: "desc4",
    },
    {
      id: 5,
      imgsrc: male5,
      description: "desc5",
    },
    {
      id: 6,
      imgsrc: male6,
      description: "desc6",
    },
    {
      id: 7,
      imgsrc: male7,
      description: "desc7",
    },
    {
      id: 8,
      imgsrc: male8,
      description: "desc8",
    },
    {
      id: 9,
      imgsrc: male9,
      description: "desc9",
    },
    {
      id: 10,
      imgsrc: male10,
      description: "desc10",
    },
    {
      id: 11,
      imgsrc: male11,
      description: "desc11",
    },
    {
      id: 12,
      imgsrc: male12,
      description: "desc12",
    },
    {
      id: 13,
      imgsrc: male13,
      description: "desc13",
    },
    {
      id: 14,
      imgsrc: male14,
      description: "desc14",
    },
    {
      id: 15,
      imgsrc: male15,
      description: "desc15",
    },
    {
      id: 16,
      imgsrc: male16,
      description: "desc16",
    },
    {
      id: 17,
      imgsrc: male17,
      description: "desc17",
    },
    {
      id: 18,
      imgsrc: male18,
      description: "desc18",
    },
    {
      id: 19,
      imgsrc: male19,
      description: "desc19",
    },
    {
      id: 20,
      imgsrc: male20,
      description: "desc20",
    },
    {
      id: 21,
      imgsrc: male21,
      description: "desc21",
    },
    {
      id: 22,
      imgsrc: male22,
      description: "desc22",
    },
    {
      id: 23,
      imgsrc: male23,
      description: "desc23",
    },
    {
      id: 24,
      imgsrc: male24,
      description: "desc24",
    },
    {
      id: 25,
      imgsrc: male25,
      description: "desc25",
    },
    {
      id: 26,
      imgsrc: male26,
      description: "desc26",
    }

  ];
  const reversedAvatars = [...avatarsfemale].reverse();

  return (
    <div className="  md:px-6 p-4 max-w-s mx-auto  ml-20 mr-20 ">
      <div className="text-center">
        <h1 className="md:text-5xl text-3xl font-bold mb-8 text-primary my-2">
          Explore AI Avatars
        </h1>
      </div>
      <div className="w-full overflow-hidden  relative">
        <motion.div className="flex gap-8 lg:w-[140%] md:w-[220%] sm:w-[700%] animate-leftslide" style={{ direction: 'rtl' }}>
          
          {reversedAvatars.map((avatar) => (
            <div key={avatar.id}>
              <div className="p-2 shadow-xl min-w-[19rem]  rounded-lg bg-white">
                <img
                  src={avatar.imgsrc}
                  alt=""
                  className="rounded-xl cursor-pointer"
                  onClick={() => navigate("/createavatar")}
                />
                <p className="text-primary text-center mt-2">
                  {avatar.description}
                </p>
              </div>
            </div>
          ))}
        
        </motion.div>
      </div>
      <div className="w-full overflow-hidden  relative">
       
        <motion.div className="flex gap-8 lg:w-[140%] md:w-[220%] sm:w-[700%] animate-rightslide">
          {avatarsmale.map((avatar) => (
            <div key={avatar.id}>
              <div className="p-2 shadow-xl min-w-[19rem]  rounded-lg bg-white">
                <img
                  src={avatar.imgsrc}
                  alt=""
                  className="rounded-xl cursor-pointer"
                  onClick={() => navigate("/createavatar")}
                />
                <p className="text-primary text-center mt-2">
                  {avatar.description}
                </p>
              </div>
            </div>
          ))}
          {avatarsmale.map((avatar) => (
            <div key={`${avatar.id}-clone`}>
              <div className="p-2 shadow-xl min-w-[19rem]  rounded-lg bg-white">
                <img
                  src={avatar.imgsrc}
                  alt=""
                  className="rounded-xl"
                  onClick={() => navigate("/createavatar")}
                />
                <p className="text-primary text-center mt-2">
                  {avatar.description}
                </p>
              </div>
            </div>
          ))}
       
        </motion.div>
      </div>

  
      
    </div>
  );
};

export default Explore;
