import React from 'react'
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const redirectToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="flex w-full h-screen loginscreen">
      <div className="w-full flex items-center justify-center">
        <div className="bg-white px-16  md:px-10 py-16 rounded-3xl border-2 border-gray">
          <h1>Dogiwa</h1>
          <h1 className="md:text-5xl text-3xl font-semibold">
            Hello Welcome Back
          </h1>
          <p className="font-medium text-lg text-gray-500 mt-4">
           Enter your regsitered email
          </p>
          <form action="" >
            <div className="mt-5">
              <div className="mb-2">
                <label className="text-lg font-medium" htmlFor="">
                  Email
                </label>
                <input
                  name="email"
                  className="w-full border-2 border-gray-100 rounded-xl p-1 mt-1 bg-transparent"
                  placeholder="Enter your email"
                />
              </div>
            </div>
            <div className="mt-5 flex flex-col gap-y-4">
            <button type="submit" className="py-1 px-4 bg-secondary font-semibold text-white rounded-xl hover:bg-primary transition-all duration-300 text-lg ">
              Send Email
            </button>
            </div>
          </form>
          <div className="mt-5">
            <button className="text-violet-500 text-base font-medium" onClick={redirectToLogin}>
              Back to login?
            </button>
          </div>
         
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
