import React from "react";
import { useNavigate } from "react-router-dom";
const Headline = () => {
  const navigate=useNavigate();
  return (
    <div className="md:px-12 pt-4 pl-4 pr-4 max-w-screen-2xl mx-auto mt-224" >
      <div className=" md:p-9 px-4 py-9 mt-20" style={{paddingBottom:"0px"}}>
        <h1 className="md:text-5xl text-4xl font-bold text-black mb-6 leading-relaxed text-center">CREATE AI AVATAR OF YOU IN 1 CLICK</h1>
        <div className="text-center">
        <button className="gradientbg py-3 px-4 rounded-lg text-white" onClick={()=>navigate("/createavatar")}>GENERATE NOW</button>
        </div>
      </div>
    </div>
  );
};

export default Headline;
