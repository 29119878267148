import React from 'react'
import aboutimage from "../images/aboutimage.png"
import { useNavigate } from 'react-router-dom'
import Dojiwa from "../images/Dojiwa.mp4"

const About = () => {
  const navigate=useNavigate();
  return (
    <div className='md:px-14 p-4 max-w-s mx-auto' id='about'>
      <div className='flex flex-col md:flex-row items-center gap-4'>
        <div className='md:w-3/2'>
          <video className="lg:h-[440px] rounded-xl" src={Dojiwa}  muted  playsInline  autoPlay loop></video>
            {/* <img src={aboutimage} alt="" /> */}
        </div>
        {/* about content */}
        <div className='md:w-1/2 m-20'>
            <h2 className='md:text-5xl text-3xl font-bold text-primary mb-5 leading-normal'>Explore a World of Characters</h2>
            <p className='text-tartiary text-lg mb-7'>Looking for a fun and creative way to express yourself online? Say hello to our AI Avatar Generator, the ultimate tool for crafting unique digital personas. With just a few clicks, you can transform yourself into a diverse range of captivating characters, each brought to life by cutting-edge artificial intelligence technology.Let your imagination run wild and transform yourself into the ultimate digital masterpiece!</p>
            <button className='bg-secondary py-2 px-5 rounded-lg hover:bg-indigo-600 text-white font-2xl' onClick={()=>navigate("/createavatar")}>Get Started</button>
        </div>
      </div>
    </div>
  )
}

export default About
