import React from "react";
import usecase1 from "../images/malecollage.png";
import usecase2 from "../images/femalecollage.png";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Features = () => {
  return (
    <div className="featureclass">
      <div
        className="my-24 md:px-14 px-4 max-w-screen-2xl mx-auto "
        id="feature"
      >
        <div className="flex lg:flex-row flex-col justify-between items-start gap-10">
          <motion.div
            variants={fadeIn("right", 0.2)}
            initial="hidden"
            animate="show"
            className="lg:w-1/4"
          >
            <h3 className="text-2xl text-primary font-bold lg:w-1/2 mb-2">
              Start with 5 Free Avatars. No Credit Card required
            </h3>
            <p className="text-base text-tartiary">
              Once you've created your AI avatar, seamlessly integrate it into
              your online presence. Use it as your profile picture on social
              media, forums, and gaming platforms to stand out from the crowd.
              Incorporate it into your website, blog, or online store to add a
              touch of personality and charm. With high-quality, customizable
              avatars at your fingertips, you'll captivate your audience and
              leave a lasting impression wherever you go.
            </p>
          </motion.div>
          {/* features card */}
          <motion.div
            variants={fadeIn("up", 0.3)}
            initial="hidden"
            animate="show"
            className="w-full lg:w-3/4"
          >
            <div className="grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 items-start md:gap-12 gap-8">
              <div className="bg-[rgba(255,255,255,0.04)] rounded-35px h-96 shadow-2xl p-8 items-center flex flex-col justify-center hover:-translate-y-4 transition-all duration-300 cursor-pointer">
                <img className="rounded-3xl" src={usecase1} alt="" />
                {/* <h5 className="text-xl font-semibold text-primary px-5 text-center mt-5">
                  AI Avatar for persona profile
                </h5> */}
              </div>
              
              <div>
                <div className="bg-[rgba(255,255,255,0.04)] rounded-35px h-96 shadow-2xl p-8 items-center flex flex-col justify-center hover:-translate-y-4 transition-all duration-300 cursor-pointer">
                  <img className="rounded-3xl" src={usecase2} alt="" />
                  {/* <h5 className="text-xl font-semibold text-primary px-5 text-center mt-5">
                    AI Avatar for your favourite character
                  </h5> */}
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Features;
