import React from 'react'
import Dojiwa from "../images/Dojiwa.mp4"
import Banner from '../shared/banner'
import bannervideo from "../images/Dojiwabannervideo.mp4"

const Home = () => {
  return (
    <div className='md:px-12 p-4 max-w-screen-2xl mx-auto mt-224 ' id='home'>
     <Banner bannervideo={bannervideo} heading="Create AI Avatars of you in 1 click" subheading="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate" btn1="Generate Yours"/>
    </div>
  )
}

export default Home
