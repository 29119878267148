import React from "react";
import { useState, useEffect } from "react";
import { FaDownload } from "react-icons/fa";
import { saveAs } from 'file-saver'
import congogif from "../images/congratulations.gif";
import select1 from "../images/image1.png"
import select2 from "../images/image2.png"
import select3 from "../images/image3.png"
import select4 from "../images/image4.png"
import select5 from "../images/image5.png"
import select6 from "../images/image6.png"
import select7 from "../images/image7.png"
import select8 from "../images/image8.png"
import select9 from "../images/image9.png"
import select10 from "../images/image10.png"
import select11 from "../images/image11.png"
import select12 from "../images/image12.png"
import select13 from "../images/image13.png"
import select14 from "../images/image14.png"
import select15 from "../images/image15.png"
import select16 from "../images/image16.png"
import select17 from "../images/image17.png"
import select18 from "../images/image18.png"
import select19 from "../images/image19.png"
import select20 from "../images/image20.png"
import select21 from "../images/image21.png"
import select22 from "../images/image22.png"
import select23 from "../images/image23.png"
import select24 from "../images/image24.png"
import select25 from "../images/image25.png"
import select26 from "../images/image26.png"
import select27 from "../images/image27.jpg"
import select28 from "../images/image28.png"
import { motion } from "framer-motion";

const Result = () => {
  //courousel avatar object
  const avatars = [
    {
      id: 1,
      imgsrc: select1,
      description: "desc1",
    },
    {
      id: 2,
      imgsrc: select2,
      description: "desc2",
    },
    {
      id: 3,
      imgsrc: select3,
      description: "desc3",
    },
    {
      id: 4,
      imgsrc: select4,
      description: "desc4",
    },
    {
      id: 5,
      imgsrc: select5,
      description: "desc5",
    },
    {
      id: 6,
      imgsrc: select6,
      description: "desc6",
    },
    {
      id: 7,
      imgsrc: select7,
      description: "desc7",
    },
    {
      id: 8,
      imgsrc: select8,
      description: "desc8",
    },
    {
      id: 9,
      imgsrc: select9,
      description: "desc9",
    },
    {
      id: 10,
      imgsrc: select10,
      description: "desc10",
    },
    {
      id: 11,
      imgsrc: select11,
      description: "desc11",
    },
    {
      id: 12,
      imgsrc: select12,
      description: "desc12",
    },
    {
      id: 13,
      imgsrc: select13,
      description: "desc13",
    },
    {
      id: 14,
      imgsrc: select14,
      description: "desc14",
    },
    {
      id: 15,
      imgsrc: select15,
      description: "desc15",
    },
    {
      id: 16,
      imgsrc: select16,
      description: "desc16",
    },
    {
      id: 17,
      imgsrc: select17,
      description: "desc17",
    },
    {
      id: 18,
      imgsrc: select18,
      description: "desc18",
    },
    {
      id: 19,
      imgsrc: select19,
      description: "desc19",
    },
    {
      id: 20,
      imgsrc: select20,
      description: "desc20",
    },
    {
      id: 21,
      imgsrc: select21,
      description: "desc21",
    },
    {
      id: 22,
      imgsrc: select22,
      description: "desc22",
    },
    {
      id: 23,
      imgsrc: select23,
      description: "desc23",
    },
    {
      id: 24,
      imgsrc: select24,
      description: "desc24",
    },
    {
      id: 25,
      imgsrc: select25,
      description: "desc25",
    },
    {
      id: 26,
      imgsrc: select26,
      description: "desc26",
    },
    {
      id: 27,
      imgsrc: select27,
      description: "desc27",
    },
    {
      id: 28,
      imgsrc: select28,
      description: "desc28",
    },
    
    
  ];

  //state for storing the format
  const [format, setFormat] = useState();

  //function for handling the format change value
  const handleSetFormat = (e) => {
    setFormat(e.target.value);
    console.log(format);
  };
  
  const [gifEnded, setgifEnded] = useState(false);
  useEffect(() => {
    const gifDuration = 10000000;
    const timer = setTimeout(() => {
      setgifEnded(true);
    }, gifDuration);

    //timer clean up after the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="my-24 px-10 w-100 max-w-screen-2xl mx-auto">
      <div>
        <span className="text-primary font-medium text-base bg-gradient-to-b from-teal-300 to-blue-500 rounded-xl py-2 px-3">
          Yours Credits:5
        </span>
        <div className="">
          {/* {!gifEnded ? <img src={congogif} alt="" /> : null} */}
          <h1 className="text-center md:text-4xl text-3xl font-bold mb-8 text-primary text-center">
            Your avatar is ready
          </h1>
          <div className="flex md:flex-row flex-col  items-start ">
            <div className="pb-6">
              <img src={select1} style={{ width: "400px" }} alt="" />
            </div>
            <div className="mx-12 gap-2">
              <form class="max-w-sm mx-auto">
                <label
                  for="format"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-2xl"
                >
                  Select a format <span className="text-red-500">*</span>
                </label>
                <select
                  id="format"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => {
                    handleSetFormat(e);
                  }}
                >
                  <option value="" selected>
                    Choose a format{" "}
                  </option>
                  <option value="png">PNG</option>
                  <option value="jpeg">JPEG</option>
                  <option value="jpg">JPG</option>
                </select>
              </form>

              <button
                className={`bg-green-700 px-4 py-2 rounded-lg text-white flex justify-content items-center disabled my-6  ${
                  format !== undefined && format !== ""
                    ? "cursor-pointer"
                    : "cursor-not-allowed"
                }`}
              >
                <FaDownload />
                &nbsp;Download
              </button>
            </div>
          </div>
          <div className="w-full overflow-hidden  relative">
            <h1 className="md:text-4xl text-3xl font-bold mb-8 text-primary text-center">
              Try More
            </h1>
            <motion.div className="flex gap-8 lg:w-[140%] md:w-[220%] sm:w-[700%] animate-rightslide">
              {avatars.map((avatar) => (
                <div key={avatar.id}>
                  <div className="p-2 shadow-xl min-w-[19rem]  rounded-lg bg-white">
                    <img src={avatar.imgsrc} alt="" className="rounded-xl" />
                    <p className="text-primary text-center mt-2">
                      {avatar.description}
                    </p>
                  </div>
                </div>
              ))}
              {avatars.map((avatar) => (
                <div key={`${avatar.id}-clone`}>
                  <div className="p-2 shadow-xl min-w-[19rem]  rounded-lg bg-white">
                    <img src={avatar.imgsrc} alt="" className="rounded-xl" />
                    <p className="text-primary text-center mt-2">
                      {avatar.description}
                    </p>
                  </div>
                </div>
              ))}
            </motion.div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default Result;
